import { FooterData } from "./footer.data"

export const FooterComponent = () => {
  return (
    <div className={`flex relative w-full max-w-[374px] justify-around items-center left-1/2 -translate-x-1/2 bottom-0 p-[8px]`}>
      {FooterData.map((data, index) => {
        const { imgLogo, text, imgStar } = data
        return (
          <div
            className="flex justify-center flex-col items-center"
            key={index}
          >
            <img src={`${imgLogo}`} width={32}/>
            <div className="max-w-[78px] text-center pt-2 text-tiny">
              {text}
            </div>
            {imgStar && <img src={`${imgStar}`} className="pt-2" />}
          </div>
        )
      })}
    </div>
  )
}
