import { useToast } from '@snapmint/ui-components';
import { createContext, useReducer } from 'react';
import { TransactionParamModel } from '../../model/transaction-param.model';
import { SendOTPReq, ValidateOTPReq } from '../../request/login.req';
import { UserService } from '../../service/user.service';
import { initialState, OtpAction, OtpReducer } from './otp.reducer';
import { FIRST_NAME_REGEX, LAST_NAME_REGEX } from '../util/validation-rule';
import { setLocalStorageKey } from '../../utils/local-stoarge.helper';
import { useUrlParamParser } from '../../page/util/url-param-parse.hook';
import { captureEvent } from '../../events/event.helper';
import { EventName } from '../../events/event-name.enum';

export const OtpContext = createContext<any>(initialState);

export const OtpStateProvider = ({ children }: any) => {
    const _transactionParamModel = useUrlParamParser();

    const [state, dispatch] = useReducer(OtpReducer, initialState);
    const toast = useToast();
    const initState = (transactionParamModel: TransactionParamModel) => {
        dispatch({
            type: OtpAction.INIT_STATE,
            payload: {
                transactionParamModel: transactionParamModel
            }
        });
    };

    const upadteOtp = (otp: string) => {
        dispatch({
            type: OtpAction.UPDATE_OTP,
            payload: {
                otp: otp
            }
        });
        const otpValid = otp.trim().length == 4;
        if (otpValid) {
            dispatch({
                type: OtpAction.VALIDATE_OTP,
                payload: {
                    error: false,
                    errMsg: ''
                }
            });
        }
    };

    const validateFirstName = () => {
        const valid =
            !state.firstName.required ||
            (FIRST_NAME_REGEX.test(state.firstName.value.trim()) && state.firstName.value.trim().length >= 1);
        if (valid) {
            dispatch({
                type: OtpAction.VALIDATE_FIRST_NAME,
                payload: {
                    error: false,
                    errMsg: ''
                }
            });
        } else {
            dispatch({
                type: OtpAction.VALIDATE_FIRST_NAME,
                payload: {
                    error: true,
                    errMsg: FIRST_NAME_REGEX.test(state.firstName.value.trim())
                        ? 'Please Enter First Name'
                        : 'Please Check Your First Name'
                }
            });
        }
    };

    const updateFirstName = (firstName: string) => {
        dispatch({
            type: OtpAction.UPDATE_FIRST_NAME,
            payload: {
                firstName: firstName.trimStart()
            }
        });
    };

    const validateLastName = () => {
        const valid =
            !state.lastName.required ||
            (LAST_NAME_REGEX.test(state.lastName.value.trim()) && state.lastName.value.trim().length >= 1);
        if (valid) {
            dispatch({
                type: OtpAction.VALIDATE_LAST_NAME,
                payload: {
                    error: false,
                    errMsg: ''
                }
            });
        } else {
            dispatch({
                type: OtpAction.VALIDATE_LAST_NAME,
                payload: {
                    error: true,
                    errMsg: LAST_NAME_REGEX.test(state.lastName.value.trim())
                        ? 'Please Enter Last Name'
                        : 'Please Check Your Last Name'
                }
            });
        }
    };

    const updateLastName = (lastName: string) => {
        dispatch({
            type: OtpAction.UPDATE_LAST_NAME,
            payload: {
                lastName: lastName.trimStart()
            }
        });
    };

    const updateLoading = (loading: boolean) => {
        dispatch({
            type: OtpAction.UPDATE_LOADING,
            payload: { loading }
        });
    };

    const updateTermAcceptence = () => {
        dispatch({
            type: OtpAction.UPDATE_TERM_ACCEPTENCE,
            payload: {}
        });
    };

    const updateTermAcceptenceError = () => {
        const isError = state.termAccepted == false;
        dispatch({
            type: OtpAction.UPDATE_TERM_ACCEPTENCE_ERROR,
            payload: {
                termAcceptedError: isError
            }
        });
    };

    const localValidateOTP = () => {
        const otpValid = state.otp.value.trim().length == 4;
        dispatch({
            type: OtpAction.VALIDATE_OTP,
            payload: {
                error: !otpValid,
                errMsg: 'OTP Should Be At Least 4 Digits'
            }
        });
    };

    const validateOTP = async (newUser: string, userRequestId?: number) => {
        if (!checkAllValid()) {
            localValidateOTP();
            validateFirstName();
            validateLastName();
            updateTermAcceptenceError();
            return;
        }

        updateLoading(true);
        increaseOtpAttemptCount();
        let res: any = {};
        try {
            setLocalStorageKey('snap_first_name', state.firstName?.value?.trim());
            setLocalStorageKey('snap_last_name', state.lastName?.value?.trim());
            const validateOTPReq = {
                mobile: state.mobile,
                otp: state.otp.value,
                newUser: newUser == 'true' ? true : false,
                userRequestId: userRequestId,
                ...(state.firstName.required && { firstName: state.firstName.value.trim() }),
                ...(state.lastName.required && { lastName: state.lastName.value.trim() })
            } as ValidateOTPReq;

            res = await UserService.validateOTP(
                state.transactionParamModel?.checkoutId ? state.transactionParamModel.checkoutId : '',
                validateOTPReq
            );
            if (res.data.status == 'error') {
                errMessageEvent(res.data.message);
                dispatch({
                    type: OtpAction.VALIDATE_OTP,
                    payload: {
                        error: true,
                        errMsg: res.data.message
                    }
                });
            }
        } catch (error: any) {
            errMessageEvent('Invalid OTP – Please Try Again');
            dispatch({
                type: OtpAction.VALIDATE_OTP,
                payload: {
                    error: true,
                    errMsg: 'Invalid OTP – Please Try Again'
                }
            });
            res = error.response;
        }

        dispatch({
            type: OtpAction.UPDATE_VALIDATE_OTP_RES,
            payload: {
                validateOtpRes: res
            }
        });
    };

    const errMessageEvent = (message: string) => {
        try {
            let eventData: any = {};
            if (_transactionParamModel) {
                const { merchantName, merchantId, checkoutId } = _transactionParamModel;
                eventData.user_channel = 'online mobile';
                eventData.cart_id = checkoutId;
                eventData.merchant_id = merchantId;
                eventData.merchant_name = merchantName;
                eventData.Error_message = message;
            }
            captureEvent(EventName.OTP_ERROR, eventData);
        } catch (err) {
            console.log(err);
        }
    };

    const reSendOtp = async (sms_type: string) => {
        resetOtpResendTimer();
        try {
            const eventDataObj = {
                cart_id: _transactionParamModel.checkoutId,
                merchant_id: _transactionParamModel.merchantId,
                merchant_name: _transactionParamModel.merchantName,
                mobile_no: _transactionParamModel.mobile,
                option_selected: sms_type
            };
            captureEvent(EventName.OTP_RESEND_OPTION_CLICKED, eventDataObj);
            const sendOTPReq = {
                mobile: state.mobile,
                calling: false,
                reSend: true,
                sms_type: sms_type,
                retryCount: state.retryCount,
                checkoutId: state.transactionParamModel?.checkoutId ? state.transactionParamModel.checkoutId : ''
            } as SendOTPReq;

            dispatch({
                type: OtpAction.UPDATE_RE_TRY_COUNT,
                payload: {
                    retryCount: state.retryCount
                }
            });
            let res = await UserService.sendOTP(
                state.transactionParamModel?.checkoutId ? state.transactionParamModel.checkoutId : '',
                sendOTPReq
            );
            if (res.data.status == 'success' || (res.data.sendOTPRes && res.data.sendOTPRes.status == 'success')) {
                updateOTPResendMessage(`OTP Re-sent Via SMS on +91 ${state.mobile}`);
                const eventDataObj = {
                    cart_id: _transactionParamModel.checkoutId,
                    merchant_id: _transactionParamModel.merchantId,
                    merchant_name: _transactionParamModel.merchantName,
                    mobile_no: _transactionParamModel.mobile
                };
                if (sms_type == 'whatsapp') {
                    captureEvent(EventName.WHATSAPP_SERVICE_SUCCESSFUL_CALL, eventDataObj);
                } else {
                    captureEvent(EventName.SMS_SERVICE_SUCCESSFUL_CALL, eventDataObj);
                }
            } else {
                if (res.data.sendOTPRes && res.data.sendOTPRes.status == 'error' && res.data.sendOTPRes.message) {
                    updateOTPResendStatus(true, res.data.sendOTPRes.message);
                } else {
                    updateOTPResendStatus(true, res.data.message);
                }
            }
        } catch (err) {
            updateOTPResendStatus(true, 'OTP resent Failed');
        }
    };
    const sendOtpViaCall = async () => {
        resetOtpResendTimer();
        try {
            const sendOTPReq = {
                mobile: state.mobile,
                calling: true,
                reSend: true
            } as SendOTPReq;
            const res = await UserService.sendOTP(
                state.transactionParamModel?.checkoutId ? state.transactionParamModel.checkoutId : '',
                sendOTPReq
            );

            if (res.data.status == 'success' || (res.data.sendOTPRes && res.data.sendOTPRes.status == 'success')) {
                updateOTPResendMessage(`OTP Re-sent Via Call on +91 ${state.mobile}`);
            } else {
                if (res.data.sendOTPRes && res.data.sendOTPRes.status == 'error' && res.data.sendOTPRes.message) {
                    updateOTPResendStatus(true, res.data.sendOTPRes.message);
                } else {
                    updateOTPResendStatus(true, res.data.message);
                }
            }
        } catch (err) {
            updateOTPResendStatus(true, 'OTP resent Failed');
        }
    };

    const checkAllValid = (): boolean => {
        const firstNameValid =
            !state.firstName.required ||
            (/^[A-Za-z][A-Za-z_  ]{0,29}$/.test(state.firstName.value.trim()) &&
                state.firstName.value.trim().length >= 1);
        const lastNameValid =
            !state.lastName.required ||
            (/^[A-Za-z][A-Za-z_  ]{0,29}$/.test(state.lastName.value.trim()) &&
                state.lastName.value.trim().length >= 1);

        const otpValid = state.otp.value.trim().length == 4;

        return firstNameValid && lastNameValid && otpValid;
    };

    const checkForCompleteValidation = () => {
        dispatch({
            type: OtpAction.UPDATE_SUBMIT_ACTIVE,
            payload: {
                submitActive: checkAllValid()
            }
        });
    };

    const updateOtpResendTimer = () => {
        let _otpReSendTime = state.otpReSendTime - 1;
        if (_otpReSendTime === 0) {
            let options = ['sms'];
            if (_transactionParamModel?.whatappAllowed) {
                options.push('whatsapp');
            }
            const eventDataObj = {
                cart_id: _transactionParamModel.checkoutId,
                merchant_id: _transactionParamModel.merchantId,
                merchant_name: _transactionParamModel.merchantName,
                mobile_no: _transactionParamModel.mobile,
                options_viewed: options
            };
            captureEvent(EventName.OTP_RESEND_OPTIONS_VIEWED, eventDataObj);
        }
        if (_otpReSendTime < 0) {
            _otpReSendTime = 0;
        }
        dispatch({
            type: OtpAction.UPDATE_OTP_RESEND_TIME,
            payload: {
                otpReSendTime: _otpReSendTime
            }
        });
    };

    const resetOtpResendTimer = () => {
        const _otpReSendTime = 30;
        dispatch({
            type: OtpAction.UPDATE_OTP_RESEND_TIME,
            payload: {
                otpReSendTime: _otpReSendTime
            }
        });
    };

    const updateOTPResendStatus = (error: boolean, message: string) => {
        dispatch({
            type: OtpAction.UPDATE_OTP_RESEND_STATUS,
            payload: {
                otpReSendError: error,
                otpReSendErrorMessage: message
            }
        });

        if (error) {
            setTimeout(() => {
                updateOTPResendStatus(false, '');
            }, 5000);
        }
    };

    const updateOTPResendMessage = (message: string) => {
        dispatch({
            type: OtpAction.UPDATE_OTP_RESEND_MESSAGE,
            payload: {
                otpReSendMessage: message
            }
        });
    };

    const increaseOtpAttemptCount = () => {
        dispatch({
            type: OtpAction.INCREASE_OTP_ATTEMPT,
            payload: {}
        });
    };

    const updateFields = (sendOTPRes: any) => {
        // if (sendOTPRes.a1 || sendOTPRes.a2) {
        if (false) {
            dispatch({
                type: OtpAction.MARK_NAME_REQUIRED,
                payload: {}
            });
        }
    };
    const posthogEventHandle = (eventName: string) => {
        try {
            let eventData: any = {};
            if (_transactionParamModel) {
                const { merchantName, merchantId, checkoutId } = _transactionParamModel;
                eventData.user_channel = 'online mobile';
                eventData.cart_id = checkoutId;
                eventData.merchant_id = merchantId;
                eventData.merchant_name = merchantName;
            }
            captureEvent(eventName, eventData);
        } catch (err) {
            console.log(err);
        }
    };

    const updateCheckPayLater = (checkPayLater: boolean) => {
        dispatch({
            type: OtpAction.UPDATE_CHECK_PAY_LATER,
            payload: {
                checkPayLater
            }
        });
    };

    const value = {
        mobile: state.mobile,
        otp: state.otp,
        loading: state.loading,
        validateOtpRes: state.validateOtpRes,
        termsAndConditionUrl: state.termsAndConditionUrl,
        privacyPolicyUrl: state.privacyPolicyUrl,
        membershipTermsUrl: state.membershipTermsUrl,
        submitActive: state.submitActive,
        termAccepted: state.termAccepted,
        termAcceptedError: state.termAcceptedError,
        otpReSendTime: state.otpReSendTime,
        otpReSendError: state.otpReSendError,
        otpReSendErrorMessage: state.otpReSendErrorMessage,
        otpReSendMessage: state.otpReSendMessage,
        otpAttemptCount: state.otpAttemptCount,
        firstName: state.firstName,
        lastName: state.lastName,
        checkPayLater: state.checkPayLater,
        initState,
        upadteOtp,
        updateFirstName,
        validateFirstName,
        updateLastName,
        validateLastName,
        updateLoading,
        validateOTP,
        reSendOtp,
        sendOtpViaCall,
        updateTermAcceptence,
        checkForCompleteValidation,
        updateOtpResendTimer,
        resetOtpResendTimer,
        updateOTPResendStatus,
        updateFields,
        posthogEventHandle,
        updateCheckPayLater,
    };

    return <OtpContext.Provider value={value}>{children}</OtpContext.Provider>;
};
