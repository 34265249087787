import { Action, FieldData } from '../../model/reducer.model';
import { TransactionParamModel } from '../../model/transaction-param.model';
import { getLocalStorageKey } from '../../utils/local-stoarge.helper';

export interface OtpPageSate {
    loading: boolean;
    mobile: string;
    otp: FieldData;
    firstName: FieldData;
    lastName: FieldData;
    termAccepted: boolean;
    termAcceptedError: boolean;
    validateOtpRes: any;
    transactionParamModel: TransactionParamModel | null;
    termsAndConditionUrl: string;
    privacyPolicyUrl: string;
    membershipTermsUrl: string;
    submitActive: boolean;
    otpReSendTime: number;
    otpReSendError: boolean;
    otpReSendErrorMessage: string;
    otpReSendMessage: string;
    otpAttemptCount: number;
    retryCount: number;
    checkPayLater: boolean;
}

export const initialState = {
    loading: false,
    mobile: '',
    termAccepted: true,
    termAcceptedError: false,
    otp: {
        value: '',
        error: false,
        errMsg: ''
    } as FieldData,
    firstName: {
        value: getLocalStorageKey('snap_first_name') ? getLocalStorageKey('snap_first_name') : '',
        error: false,
        errMsg: '',
        required: false
    } as FieldData,
    lastName: {
        value: getLocalStorageKey('snap_last_name') ? getLocalStorageKey('snap_last_name') : '',
        error: false,
        errMsg: '',
        required: false
    } as FieldData,
    validateOtpRes: null,
    transactionParamModel: null,
    termsAndConditionUrl: 'https://snapmint.com/terms-and-conditions',
    privacyPolicyUrl: 'https://snapmint.com/privacy-policy',
    membershipTermsUrl:
        'https://www.snapmintfin.com/Master%20Terms%20and%20Conditions%20For%20Purchase%20Limit%20Membership.pdf',
    submitActive: false,
    otpReSendTime: 30,
    otpReSendError: false,
    otpReSendErrorMessage: '',
    otpReSendMessage: '',
    otpAttemptCount: 0,
    retryCount: 1,
    checkPayLater: false
} as OtpPageSate;

export enum OtpAction {
    INIT_STATE = 'init_state',
    UPDATE_OTP = 'update_otp',
    VALIDATE_OTP = 'validate_otp',
    RE_SEND_OTP = 're_send_otp',
    SEND_OTP_VIA_CALL = 'send_otp_via_call',
    UPDATE_LOADING = 'update_loading',
    UPDATE_TERM_ACCEPTENCE = 'update_term_acceptence',
    UPDATE_TERM_ACCEPTENCE_ERROR = 'update_term_acceptence_error',
    UPDATE_VALIDATE_OTP_RES = 'update_validate_otp_res',
    UPDATE_SUBMIT_ACTIVE = 'update_submit_active',
    UPDATE_OTP_RESEND_TIME = 'update_otp_resend_time',
    UPDATE_OTP_RESEND_STATUS = 'update_otp_resend_status',
    UPDATE_OTP_RESEND_MESSAGE = 'update_otp_resend_message',
    INCREASE_OTP_ATTEMPT = 'INCREASE_OTP_ATTEMPT',
    VALIDATE_FIRST_NAME = 'VALIDATE_first_name',
    UPDATE_FIRST_NAME = 'update_first_name',
    VALIDATE_LAST_NAME = 'validate_last_name',
    UPDATE_LAST_NAME = 'update_last_name',
    MARK_NAME_REQUIRED = 'mark_name_required',
    UPDATE_RE_TRY_COUNT = 'update_retry_count',
    UPDATE_WHATSAPP_ALLOWED = 'update_whatsapp_allowed',
    UPDATE_CHECK_PAY_LATER = 'update_check_pay_later'
}

export const OtpReducer = (state: OtpPageSate, action: Action): OtpPageSate => {
    const { type, payload } = action;

    switch (type) {
        case OtpAction.INIT_STATE:
            return {
                ...state,
                transactionParamModel: {
                    ...payload.transactionParamModel
                },
                mobile: payload.transactionParamModel.mobile,
                otpReSendMessage: `OTP was sent on +91 ${payload.transactionParamModel.mobile}`
            };
        case OtpAction.UPDATE_OTP:
            return {
                ...state,
                otp: {
                    ...state.otp,
                    value: payload.otp
                }
            };
        case OtpAction.VALIDATE_OTP:
            return {
                ...state,
                otp: {
                    ...state.otp,
                    error: payload.error,
                    errMsg: payload.errMsg
                }
            };
        case OtpAction.UPDATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case OtpAction.UPDATE_TERM_ACCEPTENCE:
            const newTermAccepted = !state.termAccepted;
            return {
                ...state,
                termAccepted: newTermAccepted,
                termAcceptedError: newTermAccepted == false
            };
        case OtpAction.UPDATE_TERM_ACCEPTENCE_ERROR:
            return {
                ...state,
                termAcceptedError: payload.termAcceptedError
            };
        case OtpAction.UPDATE_VALIDATE_OTP_RES:
            return {
                ...state,
                validateOtpRes: payload.validateOtpRes
            };
        case OtpAction.UPDATE_SUBMIT_ACTIVE:
            return {
                ...state,
                submitActive: payload.submitActive
            };
        case OtpAction.UPDATE_OTP_RESEND_TIME:
            return {
                ...state,
                otpReSendTime: payload.otpReSendTime
            };
        case OtpAction.UPDATE_OTP_RESEND_STATUS:
            return {
                ...state,
                otpReSendError: payload.otpReSendError,
                otpReSendErrorMessage: payload.otpReSendErrorMessage
            };
        case OtpAction.UPDATE_OTP_RESEND_MESSAGE:
            return {
                ...state,
                otpReSendMessage: payload.otpReSendMessage
            };
        case OtpAction.INCREASE_OTP_ATTEMPT:
            return {
                ...state,
                otpAttemptCount: state.otpAttemptCount + 1
            };
        case OtpAction.VALIDATE_FIRST_NAME:
            return {
                ...state,
                firstName: {
                    ...state.firstName,
                    error: payload.error,
                    errMsg: payload.errMsg
                }
            };
        case OtpAction.UPDATE_FIRST_NAME:
            return {
                ...state,
                firstName: {
                    ...state.firstName,
                    value: payload.firstName,
                    error: false,
                    errMsg: ''
                }
            };
        case OtpAction.VALIDATE_LAST_NAME:
            return {
                ...state,
                lastName: {
                    ...state.lastName,
                    error: payload.error,
                    errMsg: payload.errMsg
                }
            };
        case OtpAction.UPDATE_LAST_NAME:
            return {
                ...state,
                lastName: {
                    ...state.lastName,
                    value: payload.lastName,
                    error: false,
                    errMsg: ''
                }
            };
        case OtpAction.MARK_NAME_REQUIRED:
            return {
                ...state,
                firstName: {
                    ...state.firstName,
                    required: true
                },
                lastName: {
                    ...state.lastName,
                    required: true
                }
            };
        case OtpAction.UPDATE_RE_TRY_COUNT:
            return {
                ...state,
                retryCount: payload.retryCount
            };
        case OtpAction.UPDATE_CHECK_PAY_LATER:
            return {
                ...state,
                checkPayLater: payload.checkPayLater
            };    
        default:
            throw new Error(`No case for type ${type} found in otpReducer.`);
    }
};
