import {
    SnapCard,
    Bold14Text,
    Regular12Text,
    SnapPrimaryButton,
    Regular10Text,
    SnapInputField
} from '@snapmint/ui-components';
import React, { useState, useEffect, useRef } from 'react';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { EventName } from '../../events/event-name.enum';
import { captureEvent } from '../../events/event.helper';
import { useOtp } from '../../page/otp/otp.hook';
import { useUrlParamParser } from '../../page/util/url-param-parse.hook';
import { useInterval } from '../../utils/polling.helper';
import { FooterComponent } from '../footer/footer.component';
import { BASE_PATH } from '../../enum/base.enum';
import OTPTermCondition from './tnc';
import { isPayLater } from '../../utils/pay-later.helper';
import { useApp } from '../../app.hook';

export const OtpComponent: React.FC<{ tncExperiment: boolean }> = ({ tncExperiment }) => {
    const {
        otp,
        firstName,
        lastName,
        termAccepted,
        termAcceptedError,
        otpReSendTime,
        otpReSendError,
        otpReSendErrorMessage,
        otpReSendMessage,
        submitActive,
        upadteOtp,
        validateOTP,
        reSendOtp,
        sendOtpViaCall,
        termsAndConditionUrl,
        privacyPolicyUrl,
        updateTermAcceptence,
        updateOtpResendTimer,
        otpAttemptCount,
        updateFirstName,
        validateFirstName,
        updateLastName,
        validateLastName,
        checkForCompleteValidation,
        checkPayLater
    } = useOtp();

    const _transactionParamModel = useUrlParamParser();
    const { whatappAllowed } = _transactionParamModel;
    const { isIframe } = useApp();

    const [newUser] = useQueryParam('newUser', StringParam);
    const [userRequestId] = useQueryParam('userRequestId', NumberParam);

    const [nameHintVisible, setNameHintVisible] = useState(true);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
        const notRequiredChars = ['-', 'e', '+', 'E', '.', ','];
        if (notRequiredChars.includes(event.key)) {
            event.preventDefault();
        }
        if (otp.length >= 4) {
            event.preventDefault();
        }
    };
    const emiOrPayLaterText = checkPayLater ? '2-minute online process' : 'EMI purchase is a 2-minute online process';
    useInterval(
        () => {
            updateOtpResendTimer();
        },
        otpReSendTime > 0,
        1000
    );

    const getErrorMessage = () => {
        let message = '';
        if (otp.error) {
            message = message + otp.errMsg;
        }
        if (otpReSendError) {
            if (message.length == 0) {
                message = message + otpReSendErrorMessage;
            } else {
                message = message + `/${otpReSendErrorMessage}`;
            }
        }
        if (termAcceptedError) {
            if (message.length == 0) {
                message = message + 'Please click check box above to continue';
            } else {
                message = message + '/Please click check box above to continue';
            }
        }
        return message;
    };

    useEffect(() => {
        if (otp.value.length == 4 && otpAttemptCount == 0) {
            addOTPSubmitEvent();
            validateOTP(newUser, userRequestId);
        }
    }, [otp.value]);

    useEffect(() => {
        checkForCompleteValidation();
    }, [otp.value, firstName.value, lastName.value, firstName.required, lastName.required]);

    const addOTPSubmitEvent = () => {
        let eventData: any = {};

        if (_transactionParamModel) {
            const { merchantName, merchantId, checkoutId } = _transactionParamModel;
            eventData.user_channel = 'online mobile';
            eventData.cart_id = checkoutId;
            eventData.merchant_id = merchantId;
            eventData.merchant_name = merchantName;
            eventData.tnc_version = tncExperiment ? 'medium' : 'high';
        }
        const gtmProperties = {
            ...eventData,
            order_value: _transactionParamModel.orderValue,
            mobile_no: _transactionParamModel.mobile,
        };
        captureEvent(`tnc_${tncExperiment ? 'medium' : 'high'}`, eventData, true, true);
        captureEvent(EventName.OTP_SUBMITTED, eventData, true, true, gtmProperties);
    };

    return (
        <div className="flex flex-col justify-between">
            <SnapCard className="bg-white p-8 mx-0 w-[100%]">
                {firstName.required === true && (
                    <div className="flex gap-[8px] pb-[8px] relative">
                        <SnapInputField
                            name={'firstName'}
                            value={firstName.value}
                            errorMsg={firstName.errMsg}
                            error={firstName.error}
                            onChange={(e) => {
                                updateFirstName(e.target.value);
                            }}
                            onBlur={() => {
                                validateFirstName();
                            }}
                            label={'First Name'}
                        />
                        {nameHintVisible && (
                            <span className="tooltip_arrow">
                                <p className="">
                                    Add Name as per PAN Card<br></br> for EMI Purchase Approval
                                    <img
                                        src={`${BASE_PATH}/assets/images/close.svg`}
                                        className="w-[24px] h-[24px] p-[3px] absolute top-2 right-2 cursor-pointer"
                                        onClick={() => {
                                            setNameHintVisible(false);
                                        }}
                                    ></img>
                                </p>
                            </span>
                        )}

                        <SnapInputField
                            name={'lastName'}
                            value={lastName.value}
                            errorMsg={lastName.errMsg}
                            error={lastName.error}
                            onChange={(e) => {
                                updateLastName(e.target.value);
                            }}
                            onBlur={() => {
                                validateLastName();
                            }}
                            label={'Last Name'}
                        />
                    </div>
                )}

                <Bold14Text className="text-[#000000]" text={'Please enter OTP'} />
                <Regular12Text className="text-[#787878] pt-[4px]" text={otpReSendMessage} />
                <div className="pt-[10px] pb-8">
                    <input
                        id="inputBox"
                        className={`${
                            getErrorMessage().length > 0 ? 'border-[#B75E5E]' : ''
                        } h-[56px] focus:border-[#70C0CC] focus:ring-[#70C0CC] outline-none font-roboto text-[18px] font-normal w-full rounded-md border text-center tracking-[6px]`}
                        name="otp"
                        onChange={(e) => {
                            let otp = e.target.value;
                            if (otp.length <= 4) {
                                upadteOtp(otp.replace(/[^0-9]/g, ''));
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                        value={otp.value}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        autoComplete="one-time-code"
                        onKeyPress={handleKeyPress}
                    />
                </div>
                <div className="flex z-[999999]">
                    <OTPTermCondition tncExperiment={tncExperiment} />
                </div>

                <Regular10Text
                    className={`${
                        getErrorMessage().length > 0 ? '' : 'hidden'
                    } text-[#B75E5E] mt-[6px] text-center leading-[12.1px] min-h-[12px] pb-8`}
                    text={getErrorMessage()}
                />
                {otpReSendTime == 0 && (
                    <div className="flex justify-around items-center py-8">
                        <span className="font-robotoRegular text-[#657173] text-[12px]">Resend OTP via:</span>
                        <div
                            className="flex px-4 py-[2.3px] rounded-[4px] text-[#70C0CC] border border-[#70C0CC] text-[12px] cursor-pointer"
                            onClick={() => {
                                captureEvent(EventName.OTP_RESEND_CLICKED);
                                reSendOtp('sms');
                            }}
                        >
                            <img src={`${BASE_PATH}/assets/images/sms-icon.svg`} />
                            <span className="pl-4">SMS</span>
                        </div>
                        {whatappAllowed && whatappAllowed == 'true' && (
                            <div
                                className="flex px-4 py-[2.3px] rounded-[4px] text-[#70C0CC] border border-[#70C0CC] text-[12px] cursor-pointer"
                                onClick={() => {
                                    captureEvent(EventName.OTP_RESEND_CLICKED);
                                    reSendOtp('whatsapp');
                                }}
                            >
                                <img src={`${BASE_PATH}/assets/images/whatsapp.svg`} />
                                <span className="pl-4">Whatsapp</span>
                            </div>
                        )}
                    </div>
                )}
                {otpReSendTime > 0 && (
                    <div className="flex justify-center mb-8">
                        <div className="text-[#657173] text-[12px] cursor-pointer leading-[14.52px]">
                            Resend the OTP in:{' '}
                            <span className="text-[#657173]">{`00:${`0${otpReSendTime}`.slice(-2)}`}</span>
                        </div>
                    </div>
                )}

                <SnapPrimaryButton
                    className={`text-xl w-full mt-[6px]`}
                    onClick={() => {
                        addOTPSubmitEvent();
                        validateOTP(newUser, userRequestId);
                    }}
                    text="Verify Credit Eligibility"
                    active={submitActive}
                ></SnapPrimaryButton>

                <Regular12Text className="text-left text-[#90A4A7] mt-[8px]" text={emiOrPayLaterText} />
            </SnapCard>
        </div>
    );
};
