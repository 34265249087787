import { useEffect, useState } from 'react';
import { SnapBody1, SnapCard, SnapPrimaryButton, SnapSubTitle2 } from '@snapmint/ui-components';
import { SnapInputField } from '@snapmint/ui-components';
import { RegEx } from '../../enum/enum';
import { SendOTPReq } from '../../request/login.req';
import { FooterComponent } from '../footer/footer.component';
import { useUrlParamParser } from '../../page/util/url-param-parse.hook';
import { isPayLater } from '../../utils/pay-later.helper';

export interface MobileNumberSectionProps {
    submitNumber: (sendOTPReq: SendOTPReq) => void;
    error?: string
    setError?: any
}

export const MobileNumberComponent = (props: MobileNumberSectionProps) => {
    const { submitNumber , error , setError } = props;

    const [StateValues, setStateValues] = useState({
        mobileNo: { value: '', error: true },
        inputErr: { value: '', error: false, errorMsg: '' }
    });
    const [inputFieldUsed, setInputFieldUsed] = useState(false);
    const _transactionParamModel = useUrlParamParser();
    const {
        mobileNo: { value: mobileNoVal, error: mobileNoErr },
        inputErr: { error: inputErr, errorMsg: inputErrMsg }
    } = StateValues;

    const { validMobileNumberRegEx } = RegEx;

    const handleStateChange = (name: 'mobileNo' | 'inputErr', pair: { [key: string]: string | boolean }) => {
        setStateValues((prev) => ({ ...prev, [name]: { ...prev[name], ...pair } }));
    };
    const emiOrPayLaterText = isPayLater()
        ? 'You will receive reminders on this phone number'
        : 'You will receive EMI reminders on this phone number';

    useEffect(() => {
        handleLogic();
    }, [mobileNoVal]);

    const handleLogic = () => {
        if (validMobileNumberRegEx.test(mobileNoVal)) {
            handleStateChange('mobileNo', { error: false });
            handleStateChange('inputErr', { error: false });
        } else if (mobileNoVal.length === 0) {
            handleStateChange('inputErr', { error: false });
        } else {
            handleStateChange('mobileNo', { error: true });
            handleStateChange('inputErr', {
                error: true,
                errorMsg: 'incorrect mobile number'
            });
        }
    };

    const handleSubmit = () => {
        if (validMobileNumberRegEx.test(mobileNoVal)) {
            submitNumber({
                mobile: mobileNoVal,
                calling: false,
                reSend: false
            });
        } else if (mobileNoVal.length === 0) {
            handleStateChange('inputErr', {
                error: true,
                errorMsg: 'Enter mobile number'
            });
            setInputFieldUsed(true);
        } else {
            handleStateChange('inputErr', {
                error: true,
                errorMsg: 'incorrect mobile number'
            });
            setInputFieldUsed(true);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleStateChange('mobileNo', { value: e.target.value });
        setError("")
    };
    const [inputValue, setInputValue] = useState(mobileNoVal);
    const handleInputChangeField = (e: any) => {
        const regex = /^[0-9]*$/; // allow spaces as well
        const input = e.target.value;

        if (regex.test(input)) {
            setInputValue(input);
            // handleInputChange(input); // update accountHolderName as well
        }
    };
    const handleKeyPress = (e: any) => {
        const regex = /[0-9]/g;

        if (regex.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <>
            <SnapCard className="bg-white p-8 mx-0 w-[100%]">
                <SnapSubTitle2
                    className="text-start mb-2 sm:text-xl font-[700] "
                    text=" Verify your mobile number"
                ></SnapSubTitle2>
                <SnapBody1 className="text-start text-[#787878] leading-10" text={emiOrPayLaterText}></SnapBody1>
                <SnapInputField
                    name={'input'}
                    value={inputValue}
                    onChange={(e) => (handleInputChangeField(e), handleInputChange(e), setInputFieldUsed(false))}
                    onKeyUp={(e) => handleKeyPress(e)}
                    fieldType={'phone'}
                    label={'Enter Mobile number'}
                    error={inputFieldUsed && inputErr}
                    errorMsg={inputErrMsg}
                    onBlur={() => setInputFieldUsed(true)}
                    containerClass={'mt-12'}
                    inputMode="numeric"
                ></SnapInputField>
                
                <div className="mt-4">
                    <SnapBody1
                        className="text-start text-[#787878] leading-10"
                        text="Phone number required to verify your credit eligibility"
                    ></SnapBody1>
                    {   error && <div className='text-[#B75E5E] text-[10px] font-robotoMedium mt-4'>{error}</div> } </div>                <div className="mt-8">
                    <SnapPrimaryButton
                        className={`text-xl w-full h-max py-8`}
                        onClick={handleSubmit}
                        text=" Get OTP"
                        active={!mobileNoErr}
                    ></SnapPrimaryButton>
                </div>
            </SnapCard>
        </>
    );
};