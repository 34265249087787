import React, { useEffect, useState } from 'react';
import { OrderDetailSuperComponent } from '../../component/order-detail/order-detail-super-component';
import { SnapCard, Bold14Text, SnapPrimaryButton, Regular12Text } from '@snapmint/ui-components';
import OTPTermCondition from '../../component/otp/tnc';
import { UserService } from '../../service/user.service';
import { useUrlParamParser } from '../util/url-param-parse.hook';
import { useRedirectionHelper } from '../util/use-redirection.hook';
import { PreEmiLoaderComponent } from '../../component/loader/pre-emi-loader.component';
import { FooterComponent } from '../../component/footer/footer.component'
import { captureEvent } from '../../events/event.helper'
import { useApp } from '../../app.hook'
import { EventName } from '../../events/event-name.enum'

const OtpBypassConfirm = () => {
    const [loading, setLoading] = useState(false);
    const transactionParamModel = useUrlParamParser();
    const { checkRedirection } = useRedirectionHelper();
    const { checkoutId, merchantId, merchantName, orderValue, userId } = transactionParamModel;
    const { getViewtype } = useApp();

    const captureTncEvents = async (eventName: string, properties = {}) => {
        const viewType = await getViewtype();
        captureEvent(eventName, {
            cart_id: checkoutId,
            merchant_id: merchantId,
            merchant_name: merchantName,
            order_value: orderValue,
            view_type: viewType,
            user_id: userId,
            ...properties
        });
    };

    const handleContinueClick = async () => {
        captureTncEvents(EventName.TNC_CONSENT_CLICKED);
        try {
            setLoading(true);
            await UserService.acceptTnc(checkoutId as string);
            const res = await UserService.moveNext(checkoutId as string, {});
            checkRedirection(res.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        captureTncEvents(EventName.TNC_PV);
    }, []);

    return (
        <div className="flex flex-col justify-between h-full">
            <div className="max-w-[374px] pt-[8px] px-[16px] mx-auto">
                <OrderDetailSuperComponent />
                {loading ? (
                    <PreEmiLoaderComponent />
                ) : (
                    <SnapCard className="bg-white p-8 mx-0 w-[100%]">
                        <div className="flex gap-[8px] pb-[8px] relative">
                            <p className="text-[#014751] mx-auto leading-[24px] font-openSansMedium !text-[18px]">
                                Checking your Eligibility
                            </p>
                        </div>
                        <img src="/assets/images/otp-laptop.svg" className="my-[24px] mx-auto" />
                        <OTPTermCondition tncExperiment />
                        <SnapPrimaryButton
                            onClick={handleContinueClick}
                            className={`text-[16px] w-full h-[35px]  mt-[6px]`}
                            text="Continue"
                            active={true}
                        />
                        <div className="flex gap-[8px] pb-[8px] relative">
                            <Regular12Text
                                className="text-left !mx-auto text-[#90A4A7] mt-[8px]"
                                text="EMI purchase is a 2-minute online process "
                            />
                        </div>
                    </SnapCard>
                )}
            </div>
            {!loading && <FooterComponent />}
        </div>
    );
};

export default OtpBypassConfirm;
