import './App.css';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { useHttpIntercepter } from './utils/http.intercepter';
import { Suspense, useEffect, useState } from 'react';
import { Medium16Text, Medium18Text, Regular16Text, ToastProvider, getClassName } from '@snapmint/ui-components';
import { useSeon } from './page/util/seon.hook';
import { usePostHogCapture } from './events/event.hooks';
import { PopUpBlockedComponent } from './component/pop-up-blocker/pop-up-blocked.component';
import { useApp } from './app.hook';
import { PopUpBlockedSmallComponent } from './component/pop-up-blocker/pop-up-blocker-small.component';
import { NativeBridge } from './bridge/native.bridge';
import { captureEvent } from './events/event.helper';
import { EventName } from './events/event-name.enum';
import { PreEmiLoaderComponent } from './component/loader/pre-emi-loader.component';
import HomePage from './page/home/home.page';
import MobilePage from './page/mobile/mobile.page';
import OTPPage from './page/otp/otp.page';
import { useHotJarCapture } from './events/hotjar.hook';
import { isWebView, isHeaderVisible } from './utils/web-view.helper';
import { lazyWithRetry } from './utils/lazy.helper';
import { MerchantService } from './service/merchant.service';
import { TransactionParamModel } from './model/transaction-param.model';
import { useUrlParamParser } from './page/util/url-param-parse.hook';
import Header from './component/header/header.component';
import { useCookieHelper } from './utils/cookie.helper';
import { getLocalStorageKey, removeLocalStoregaeKey, setLocalStorageKey } from './utils/local-stoarge.helper';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { getUnleashConfig } from './utils/unleash';
import { PersonalLoan } from './enum/enum';
import PersonalLoanLoader from './component/loader/personal-loan-loader.component';
import OtpBypass from './page/otp/otp-bypass.page'
import OtpBypassConfirm from './page/otp/otp-bypass-confirm'

const ProfilePage = lazyWithRetry(() => import('./page/profile/profile.page'));
const AddressPage = lazyWithRetry(() => import('./page/address/address.page'));
const IncomeVerifyPage = lazyWithRetry(() => import('./page/income-verify/income-verify.page'));
const PlanPage = lazyWithRetry(() => import('./page/plan/plan.page'));
const KycPage = lazyWithRetry(() => import('./page/kyc/kyc.page'));
const AutoDebitPage = lazyWithRetry(() => import('./page/auto-debiit/auto-debit.page'));
const TxnProcessingPage = lazyWithRetry(() => import('./page/txn-processing/txn-processing.page'));
const TxnSuccessPage = lazyWithRetry(() => import('./page/txn-success/txn-success.page'));
const TxnFailedPage = lazyWithRetry(() => import('./page/txn-failure/txn-failure.page'));
const NotFoundPage = lazyWithRetry(() => import('./page/not-found/not-found.page'));
const DPPage = lazyWithRetry(() => import('./page/dp/dp.page'));
const SelfiePage = lazyWithRetry(() => import('./page/selfie/selfie.page'));
const BroadcastTabPage = lazyWithRetry(() => import('./page/broadcast-tab/broadcast-tab.page'));
const CookieBlockedPage = lazyWithRetry(() => import('./page/cookie-bolcked.page'));
const PlanPageV2 = lazyWithRetry(() => import('./page/plan-v2/plan-v2.page'));
const PaynimoPage = lazyWithRetry(() => import('./page/auto-debiit/paynimo.page'));
const BSPage = lazyWithRetry(() => import('./page/bs/bs.page'));
const UserNamePage = lazyWithRetry(() => import('./page/user-name/user-name.page'));
const TravelPlanPage = lazyWithRetry(() => import('./page/travel-plan/travel-plan.page'));
const RpdPage = lazyWithRetry(() => import('./page/rpd/rpd.page'));

const App = () => {
    const { isCookieEnabled } = useCookieHelper();
    useHttpIntercepter();
    useSeon();
    usePostHogCapture();
    useHotJarCapture();

    const {
        isIframe,
        viewType,
        popUpBlocked,
        smallView,
        updateViewType,
        updateIsIframe,
        updateMerchantHeaderDetails,
        headerDetails
    } = useApp();
    const updateContext = useUnleashContext();

    const [closeAlert, setCloseAlert] = useState(false);
    const transactionParamModel = useUrlParamParser();
    const { ckType, isPlFlow } = transactionParamModel
    const unleash_config = getUnleashConfig(
        transactionParamModel?.merchantId,
        transactionParamModel?.userId || '',
        transactionParamModel?.mobile
    );
    const openCloseAlert = () => {
        setCloseAlert(true);
    };

    const closeWindow = () => {
        if (window && window.parent) {
            (window.parent as any).postMessage({ closeIframe: true }, '*');
        }
    };

    useEffect(() => {
        let _isIframe = false;
        if (window.location !== window.parent.location) {
            // The page is in an iframe
            _isIframe = true;
        }
        const { merchantId, checkoutId, isSuperCheckout, orderValue } = transactionParamModel;
        (async () => {
            const viewType = await NativeBridge.checkForNative();
            const _isWebView = isWebView(viewType);
            updateIsIframe(_isIframe);
            captureEvent(
                EventName.LANDED_ON_CHECKOUT,
                {
                    is_iframe: _isIframe,
                    merchant_id: merchantId,
                    cart_id: checkoutId,
                    is_super_checkout: isSuperCheckout,
                    view_type: _isWebView ? 'app' : 'web',
                    order_value: orderValue
                },
                false,
                true
            );
            if (isSuperCheckout === 'true') {
                captureEvent(EventName.SUPER_CHECKOUT, {}, false, true);
            }
        })();
    }, [transactionParamModel.isSuperCheckout]);

    const fetchNativeType = async () => {
        const viewType = await NativeBridge.checkForNative();
        const { merchantId, checkoutId } = transactionParamModel;

        let _isWebView = isWebView(viewType);
        updateViewType(viewType);
        captureEvent(EventName.LANDED_ON_WEB_VIEW_CHECKOUT, {
            is_web_view: _isWebView,
            view_type: viewType,
            merchant_id: merchantId,
            cart_id: checkoutId
        });
    };

    const getMerchantHeaderDetails = async () => {
        const { merchantId, checkoutId } = transactionParamModel;
        const res = await MerchantService.getMerchantHeaderDetails(merchantId, checkoutId);
        updateMerchantHeaderDetails({
            isLoaded: true,
            ...res.data
        });
    };

    const captureABTestEvent = () => {
        const abTestData = getLocalStorageKey('unleashABTestData');
        const { merchantId, checkoutId } = transactionParamModel;
        if (!abTestData) {
            return;
        }

        const abTestDataParsed = JSON.parse(abTestData);
        const { merchant_id: abMerchanID = null, variant } = abTestDataParsed;

        if (abMerchanID === merchantId) {
            captureEvent(EventName.CHECKOUT_AB_CAPTURE, {
                variant,
                merchant_id: abMerchanID,
                cart_id: checkoutId
            });
            removeLocalStoregaeKey('unleashABTestData');
        }
    };

    useEffect(() => {
        if ('clarity' in window) {
            if (ckType === 'ixigo_travel') {
                (window as any).clarity('start', {
                    sampleRate: 100,
                    video: true
                });
            }
        }
        (async () => {
            await fetchNativeType();
            await getMerchantHeaderDetails();
            captureABTestEvent();
            captureEvent(EventName.NEW_CHECKOUT, {}, false, true);
        })();
    }, []);

    useEffect(() => {
        if (transactionParamModel) {
            const { merchantId, orderValue, mobile, cartId } = transactionParamModel;
            const properties = {
                checkout_merchant_id: merchantId,
                checkout_order_value: orderValue,
                checkout_mobile_number: mobile,
                checkout_id: cartId
            };
            captureEvent(EventName.CHECKOUT_CLARITY_BASIC_PROPERTIES, {}, false, true, { properties }, false);
        }
    }, [transactionParamModel.cartId]);

    useEffect(() => {
        const { eId } = transactionParamModel;
        if (eId) {
            setLocalStorageKey('eId', eId);
        }
    }, []);

    useEffect(() => {
        if (transactionParamModel) {
            const { merchantId, orderValue, mobile, checkoutId, userId } = transactionParamModel;
            const properties = {
                checkout_merchant_id: merchantId,
                checkout_order_value: orderValue,
                checkout_mobile_number: mobile,
                checkout_id: checkoutId as string,
                checkout_user_id: userId as string
            };
            captureEvent(EventName.CHECKOUT_CLARITY_BASIC_PROPERTIES, {}, false, true, properties, false);
        }
    }, [transactionParamModel.merchantId, transactionParamModel.userId]);

    const { showMerchantHeader = false } = headerDetails;
    const showHeader = isHeaderVisible(viewType);
    const isSnapmintHeader = !showMerchantHeader && showHeader;
    const isMerchantHeader = showMerchantHeader && showHeader;
    const isTravelCart = transactionParamModel && transactionParamModel.ckType === 'ixigo_travel';

    const checkoutContainerClassNames = getClassName({
        'mt-[12px] !h-[calc(100vh-64px)]': isMerchantHeader && isIframe,
        'mt-[64px] !h-[calc(100vh-64px)]': isMerchantHeader && !isIframe,
        'mt-[48px] !h-[calc(100vh-48px)]': isSnapmintHeader && !isIframe && !isPlFlow,
        'mt-[40px] !h-[calc(100vh-40px)]': !isIframe && isPlFlow as boolean
    }, 'h-full');

    useEffect(() => {
        updateContext({ properties: { merchant_id: transactionParamModel.merchantId } });
    }, [transactionParamModel.merchantId]);

    return (
        <ToastProvider>
            <div className="md:max-h-[600px] mx-auto">
                <Header openCloseAlert={openCloseAlert} isTravelCart={isTravelCart} />
                <div
                    className={`w-[100%] h-[100vh] mx-auto overflow-scroll scroll-hide bg-gradients ${
                        isPlFlow ? 'relative pl-flow-background' : 'bg-gradients'
                    } ${isIframe ? 'h-[calc(100vh-40px)]' : 'h-[100vh]'}`}
                >
                    <div className={checkoutContainerClassNames}>
                        <Suspense
                            fallback={
                                <div className="max-w-[374px] mx-auto mt-[204px]">
                                    {isPlFlow ? <PersonalLoanLoader /> : <PreEmiLoaderComponent />}
                                </div>
                            }
                        >
                            <Routes>
                                <Route path="/" element={<HomePage isCookieEnabled={isCookieEnabled} />} />
                                <Route path="/login" element={<MobilePage />} />
                                <Route path="/otp" element={<OTPPage />} />
                                <Route path="/profile" element={<ProfilePage />} />
                                <Route path="/user-name" element={<UserNamePage />} />
                                <Route path="/address" element={<AddressPage />} />
                                <Route path="/income-verify" element={<IncomeVerifyPage />} />
                                <Route path="/:id/income-verify" element={<IncomeVerifyPage />} />
                                <Route path="/plan" element={<PlanPage />} />
                                <Route path="/plan-v2" element={<PlanPageV2 />} />
                                <Route path="/travel-plan" element={<TravelPlanPage />} />
                                <Route path="/kyc" element={<KycPage />} />
                                <Route path="/selfie" element={<SelfiePage />} />
                                <Route path="/auto-debit" element={<AutoDebitPage />} />
                                {/* Below route is similar to auto debit */}
                                <Route path="/dp" element={<DPPage />} />
                                <Route path="/rpd" element={<RpdPage />} />
                                <Route path="/order-processing" element={<TxnProcessingPage />} />
                                <Route path="/success" element={<TxnSuccessPage />} />
                                <Route path="/failure" element={<TxnFailedPage />} />
                                <Route path="/reject" element={<h1>Rejected</h1>} />
                                <Route path="/broadcast-tab" element={<BroadcastTabPage />} />
                                <Route path="/paynimo" element={<PaynimoPage />} />
                                <Route path="/cookie-blocked" element={<CookieBlockedPage />} />
                                <Route path="/bs" element={<BSPage />} />
                                <Route path="/otp-bypass" element={<OtpBypass />} />
                                <Route path="/otp-bypass-confirm" element={<OtpBypassConfirm />} />
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </Suspense>
                    </div>

                    {popUpBlocked && <>{smallView ? <PopUpBlockedSmallComponent /> : <PopUpBlockedComponent />}</>}

                    {closeAlert && (
                        <div className="bg-[#00000060] h-[100%] w-[100%] fixed top-0 z-[9999999]">
                            <div className="max-w-[374px] bg-[#FFFFFF] fixed w-[100%] bottom-0 flex flex-col items-center rounded-t mx-auto left-0 right-0">
                                <Medium18Text
                                    text="Cancel EMI Purchase"
                                    className="mt-[24px] px-[16px] text-[#000000]"
                                />
                                <Regular16Text
                                    text="Your EMI purchase is ongoing. Are you sure you want to cancel the purchase?"
                                    className="mt-[46px] px-[16px] text-[#000000]"
                                />

                                <div className="w-full flex flex-row justify-between mt-[46px] mb-[24px]">
                                    <Medium16Text
                                        text="NO"
                                        onClick={() => setCloseAlert(false)}
                                        className="text-[#368995] px-[16px] cursor-pointer"
                                    />
                                    <Medium16Text
                                        text="YES, CANCEL"
                                        onClick={() => closeWindow()}
                                        className="text-[#3D494B] px-[16px] cursor-pointer"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ToastProvider>
    );
};

export default App;
